import React from 'react';
import { connect } from 'react-redux';
import Page404 from './404';
import styled from 'styled-components';
import PaymentMethodsContainer from '../modules/PaymentMethods/PaymentMethodsContainer';
import AddGiftCardButton from '../components/Buttons/AddGiftCardButton';
import GiftCardContainer from '../modules/GiftCard/giftCardContainer';
import appConfig from '../../app-config';

const Wrapper = styled.div`
  @media (min-width: 786px) {
    margin-left: 20%;
  }
`;

const PaymentMethodsPage = ({ isAuthenticated, sourceConfig }) => {
  const isGiftCardEnabled = sourceConfig.payment_methods.includes('giftcard');
  if (isAuthenticated) {
    return (
      <Wrapper>
        <PaymentMethodsContainer />
        {/* {isGiftCardEnabled && <AddGiftCardButton />}
        {isGiftCardEnabled && <GiftCardContainer />} */}
      </Wrapper>
    );
  } else {
    return <Page404 />;
  }
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.authReducer.isAuthenticated,
  sourceConfig: state.authReducer.appConfig,
});

export default connect(mapStateToProps)(PaymentMethodsPage);
