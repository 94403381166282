import React, { useState } from 'react';
import styled from 'styled-components';
import Card from '../../../components/Card';
import { Caption1 } from '../../../components/Typography';
import appConfig from '../../../../app-config';

const Wrapper = styled(Card)`
  padding: 19px 16px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
  background-color: #f1f1f1;
`;

const CardWrapper = styled.div`
  display: flex;
  //   grid-auto-flow: column;
  //   grid-gap: 8px;
  justify-content: flex-start;
  align-items: center;
`;

const Text = styled(Caption1)`
  font-size: 16px;
  margin-left: 15px;
`;

const PayOnArrivalCard = ({}) => {
  return (
    <Wrapper>
      <CardWrapper>
        <i className="fas fa-hand-holding-usd" style={{ fontSize: '32px' }} />
        <Text>
          {appConfig.appSource === 'feastify'
            ? 'Pay with Card in Person'
            : 'Pay on Arrival'}
        </Text>
      </CardWrapper>
    </Wrapper>
  );
};

export default PayOnArrivalCard;
